<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              Daromad Malumotlari {{ $t('ACTION.UPDATE') }}
            </h3>
          </div>
        </div>
        <div v-if="loading" class="text-center my-5">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
          ></b-spinner>
        </div>
        <div v-if="Object.keys(mainIncomeCategoryListTest)" class="card-body">
          <!-- {{ mainIncomeCategory }} -->
          <!-- <p>2</p> -->
          <!-- {{ mainIncomeCategoryListTest2 }} -->
          <!-- <p>ddd</p> -->
          <!-- {{ mainIncomeCategory }} -->
          <div v-if="!loading">
            <div>
              <div v-if="mainAsseta.length !== 0">
                <v-autocomplete
                  dense
                  :items="mainAsseta"
                  v-model="mainIncomeCategory"
                  item-text="name"
                  item-value="id"
                  @change="test(mainIncomeCategory)"
                  return-object
                  :attach="true"
                  no-data-text="Malumot topilmadi"
                  label="Asosiy Kirim"
                  outlined
                ></v-autocomplete>
              </div>
              <!-- {{ mainIncomeCategory2 }} -->
              <div v-if="mainIncomeCategoryListTest !== undefined">
                <!-- {{ mainIncomeCategory2 }} -->
                <v-autocomplete
                  dense
                  :items="mainIncomeCategoryListTest.incomecategory_set"
                  v-model="mainIncomeCategory2"
                  item-text="name"
                  return-object
                  item-value="id"
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                ></v-autocomplete>
              </div>

              <!-- {{ mainIncomeCategoryListTest2 }} -->
              <!-- {{ mainIncomeCategory3 }} -->
              <!-- {{ mainIncomeCategoryListTest2 }} -->
              <div v-if="mainIncomeCategoryListTest2.length == 0">
                <v-autocomplete
                  dense
                  :items="mainIncomeCategoryListTest2"
                  v-model="mainIncomeCategory3"
                  item-text="name"
                  return-object
                  item-value="id"
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                ></v-autocomplete>
              </div>
            </div>
            <v-container fluid>
              <v-row justify="space-between">
                <v-col cols="4" class="pl-0 py-0">
                  <v-text-field
                    :error-messages="income_amountErrors"
                    v-model="income_amount"
                    dense
                    v-currency="options"
                    label="Summa"
                    outlined
                    :success="income_amount !== ''"
                    @input="$v.income_amount.$touch()"
                    @blur="$v.income_amount.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-0 py-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :error-messages="oper_dateErrors"
                        v-model="oper_date"
                        label="Sana"
                        dense
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :success="oper_date !== ''"
                        @input="$v.oper_date.$touch()"
                        @blur="$v.oper_date.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="oper_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- <v-col cols="12" class="pl-0 py-0"></v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="mainIncomeErrors"
                  @input="$v.mainIncome.$touch()"
                  @blur="$v.mainIncome.$touch()"
                  @change="changeMainIncome"
                  :items="mainProfitSubCategory"
                  :success="
                    mainIncome !== '' &&
                      mainIncome !== null &&
                      mainIncome !== undefined
                  "
                  v-model="mainIncome"
                  item-text="name"
                  dense
                  return-object
                  item-value="id"
                  label="Asosiy Kirim"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  dense
                  :error-messages="mainIncomeCategoryErrors"
                  @input="$v.mainIncomeCategory.$touch()"
                  @blur="$v.mainIncomeCategory.$touch()"
                  @change="changeMainIncomeCategory"
                  :items="mainIncomeCategoryList"
                  v-model="mainIncomeCategory"
                  :success="
                    mainIncomeCategory !== '' &&
                      mainIncomeCategory !== null &&
                      mainIncomeCategory !== undefined
                  "
                  item-text="name"
                  return-object
                  item-value="id"
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  dense
                  :items="mainIncomeSubCategoryList"
                  v-model="mainIncomeSubCategory"
                  :success="
                    mainIncomeSubCategory !== '' &&
                      mainIncomeSubCategory !== null &&
                      mainIncomeSubCategory !== undefined
                  "
                  no-data-text="Malumot topilmadi"
                  :hide-no-data="true"
                  item-text="name"
                  item-value="id"
                  return-object
                  label="SubKategoriya"
                  outlined
                ></v-autocomplete>
              </v-col> -->
                <v-col cols="12" class="pl-0 py-0">
                  <v-textarea
                    outlined
                    label="Comment"
                    v-model="comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <div class="d-flex justify-content-start border-top pt-10">
              <div class="mr-2">
                <v-btn @click="save" color="success">Saqlash</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      loading: true,
      mainIncomeCategoryListTest: {},
      mainIncomeCategoryListTest2: {},
      incomecategory_setList: [],
      incomecategory_setListId: '',
      mainIncomeCategory3: '',
      mainIncomeCategory2: '',
      mainIncomeSubCategory: null,
      mainIncomeCategory: '',
      mainIncomeSubCategoryList: [],
      mainIncomeCategoryList: [],
      mainIncome: '',
      oper_date: '',
      income_amount: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },
  validations: {
    oper_date: {
      required
    },
    mainIncome: {
      required
    },
    mainIncomeCategory: {
      required
    },
    income_amount: {
      required
    }
  },
  created() {
    // this.$store.dispatch('getMainProfitSubCategorySpecial').then(() => {
    //   this.$store.dispatch('getIncomeListDetails', this.$route.params.id)
    // })
    this.$store.dispatch('getIncomeListDetails', this.$route.params.id)
    this.$store.dispatch('getMainProfitSubCategorySpecial')
  },
  watch: {
    mainIncomeCategory2(val) {
      // console.log(val)
      // console.log(val)
      this.mainIncomeCategoryListTest2 = val.incometype_set
    },
    // mainIncomeCategory: function(val) {
    // console.log(22, val)

    // val.forEach(element => {
    //   if (element.id)
    // });
    // val.incomecategory_set.forEach(element => {
    //   if ((val.id = element.incomecategory_set.main_type)) {
    //     this.incomecategory_setList = [...element.incomecategory_set]
    //     console.log(this.incomecategory_setList)
    //   }
    // })
    // },
    // mainExp: function(val) {
    //   this.category = val.expenditurecategory_set
    // },
    // expCategory: function(val) {
    //   if (val !== null) this.category2 = val.expendituretype_set
    // },
    mainAsseta: function (val) {
      // console.log(val)
      // console.log(this.mainIncomeCategory)
      this.mainIncomeCategoryListTest = val.find(
        (x) => x.id == this.mainIncomeCategory
      )

      if (this.mainIncomeCategoryListTest !== undefined) {
        this.loading = true
        this.mainIncomeCategoryListTest.incomecategory_set.forEach(
          (element) => {
            if (this.mainIncomeCategory2 == element.id) {
              this.loading = true
              // console.log('elements', element)
              element.incometype_set.forEach((element2) => {
                if (element2.id == this.mainIncomeCategory3) {
                  this.mainIncomeCategoryListTest2 = element2
                  this.loading = false
                }
              })
            }

            // console.log(this.mainIncomeCategoryListTest.incomecategory_set)
            // console.log(element)
          }
        )
      }

      // this.mainIncomeCategoryListTest2 = this.mainIncomeCategoryListTest.incometype_set

      // console.log(this.mainIncomeCategoryListTest)
      //
      // console.log('maintresr', this.mainIncomeCategoryListTest2)
    },
    detail: function (val) {
      this.income_amount = val.income_amount
      this.oper_date = val.oper_date
      this.mainIncomeCategory = val.income_type_description.main_income_info.id
      this.comment = val.comment
      this.mainIncomeCategory2 = val.income_type_description.category_info.id
      this.mainIncomeCategory3 = val.income_type_description.id

      // this.mainIncome = val.income_type_description.id
      // this.mainIncomeCategory =
      //   val.income_type_description.main_income_info.id
      // eslint-disable-next-line no-extra-boolean-cast
      // this.mainIncomeSubCategory = Boolean(val.income_type_description)
      //   ? val.income_type_description.id
      //   : null
      // this.changeMainIncome()
      // this.changeMainIncomeCategory()
    }
  },
  computed: {
    mainAsseta() {
      return this.$store.state.requests.mainProfitSubCategorySpecial
    },
    // mainAsseta2() {
    //   return this.$store.state.requests.mainProfitSubCategorySpecial
    //     .incomecategory_set
    // },
    detail() {
      return this.$store.state.requests.incomeListDetails
    },
    mainProfitSubCategory() {
      return this.$store.state.requests.mainProfitSubCategorySpecial
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainIncomeErrors() {
      const errors = []
      if (!this.$v.mainIncome.$dirty) return errors
      !this.$v.mainIncome.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainIncomeCategoryErrors() {
      const errors = []
      if (!this.$v.mainIncomeCategory.$dirty) return errors
      !this.$v.mainIncomeCategory.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    income_amountErrors() {
      const errors = []
      if (!this.$v.income_amount.$dirty) return errors
      !this.$v.income_amount.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  filters: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Daromad Malumotlari O`zgartirish' }
    ])
  },
  methods: {
    test(val) {
      // console.log(val)
      this.mainIncomeCategoryListTest = val
      this.mainIncomeCategory3 = ''
    },
    // changeMainIncome() {
    //   this.mainIncomeCategoryList = []
    //   this.mainIncomeCategoryList = [
    //     ...this.mainProfitSubCategory.find(
    //       x =>
    //         x.id ==
    //         (this.mainIncome.id !== undefined
    //           ? this.mainIncome.id
    //           : this.mainIncome)
    //     ).incomecategory_set
    //   ]
    //   console.log('this.mainIncomeCategoryList', this.mainIncomeCategoryList)
    // },
    // changeMainIncomeCategory() {
    //   this.mainIncomeSubCategoryList = []
    //   console.log(this.mainIncomeCategoryList)
    //   this.mainIncomeSubCategoryList = [
    //     ...this.mainIncomeCategoryList.find(
    //       x =>
    //         x.id ==
    //         (this.mainIncomeCategory.id !== undefined
    //           ? this.mainIncomeCategory.id
    //           : this.mainIncomeCategory)
    //     ).incometype_set
    //   ]
    //   console.log(
    //     'this.mainIncomeSubCategoryList',
    //     this.mainIncomeSubCategoryList
    //   )
    // },
    toFloat(val) {
      if (typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return val
    },
    save() {
      // this.$v.$touch()
      // if (this.$v.$invalid) {
      // this.submitStatus = 'ERROR'
      // Swal.fire({
      //   title: 'Xato toldirilgan',
      //   text: "Ma'lumotlar yuborilmadi!",
      //   icon: 'error',
      //   confirmButtonClass: 'btn btn-secondary'
      // })
      // } else {
      // this.submitStatus = 'PENDING'
      // this.submitStatus = 'OK'
      const id = {
        id: this.$route.params.id
      }
      const data = {
        income_amount: this.toFloat(this.income_amount),
        oper_date: this.oper_date,
        comment: this.comment

        // income_maintype: this.
        // income_category:
        // income_type:

        // // income_maintype: this.mainIncome.id,
        // // eslint-disable-next-line no-extra-boolean-cast
        // income_maintype: Boolean(this.mainIncome.id)
        //   ? this.mainIncome.id
        //   : this.mainIncome,
        // // income_category: this.mainIncomeCategory.id,
        // // eslint-disable-next-line no-extra-boolean-cast
        // income_category: Boolean(this.mainIncomeCategory.id)
        //   ? this.mainIncomeCategory.id
        //   : this.mainIncomeCategory,
        // income_type:
        //   // eslint-disable-next-line no-extra-boolean-cast
        //   Boolean(this.mainIncomeSubCategory)
        //     ? this.mainIncomeSubCategory.id !== undefined
        //       ? this.mainIncomeSubCategory.id
        //       : this.mainIncomeSubCategory
        //     : null
      }
      if (
        this.mainIncomeCategory.id !== undefined ||
        this.mainIncomeCategory2.id !== undefined ||
        this.mainIncomeCategory3.id !== undefined
      ) {
        data.income_maintype = this.mainIncomeCategory.id
        data.income_category = this.mainIncomeCategory2.id
        data.income_type = this.mainIncomeCategory3.id

        if (this.mainIncomeCategory.id == undefined) {
          data.income_maintype = this.mainIncomeCategory
        }
      }
      // console.log(data)

      this.$store.dispatch('updateIncomeList', { id, data })
    }
  }
}
</script>
